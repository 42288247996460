/** @jsxImportSource @emotion/react */
import { Editor } from '@tiptap/react';
import { useEffect, useState, useCallback } from 'react';

import Icon from '../components/Icon';
import IcoArrowDropDownRounded from 'components/icon/icons/IcoArrowDropDownRounded';

import PyMuiMenu from '../components/generalComponents/PyMuiMenu';
import PyMuiMenuItem from '../components/generalComponents/PyMuiMenuItem';
import { styled } from '@mui/material';
import { PyMuiButton } from '../components/generalComponents';

import { createTextStyleHandler, getFontSizeParentAttribute } from '../helpers/textStyleHelpers';

export const DEFAULT_SIZE = '12';

const defaultFontSizes = [
  { name: '8', value: '8px' },
  { name: '9', value: '9px' },
  { name: '10', value: '10px' },
  { name: DEFAULT_SIZE, value: `${DEFAULT_SIZE}px` },
  { name: '14', value: '14px' },
  { name: '16', value: '16px' },
  { name: '18', value: '18px' },
  { name: '24', value: '24px' },
  { name: '30', value: '30px' },
  { name: '42', value: '42px' },
];

const isActive = (editor: Editor | null, fontSize: string) => editor?.isActive('fontSize', { fontSize }) ?? false;

const FontSizeContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'stretch',
});

type Props = {
  editor: Editor;
};

const FontSize = ({ editor }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selected, setSelected] = useState<string>(DEFAULT_SIZE);

  const handleOpenMenu = useCallback<React.MouseEventHandler<HTMLElement>>((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => setAnchorEl(null), []);

  const handleSelectFontSize = useCallback(
    (name: string, value: string) => {
      editor?.chain().focus()[value ? 'setFontSize' : 'unsetFontSize'](value).run();

      setSelected(name || DEFAULT_SIZE);
      handleClose();
    },
    [editor, handleClose]
  );

  useEffect(() => {
    const updateSelectedSize = createTextStyleHandler({
      attributeName: 'fontSize',
      getParentAttribute: getFontSizeParentAttribute,
      parseValue: (value) => parseInt(value),
    });

    editor.on('transaction', (event) => updateSelectedSize(event, setSelected));
    return () => {
      editor.off('transaction', (event) => updateSelectedSize(event, setSelected));
    };
  }, [editor]);

  return (
    <FontSizeContainer>
      <PyMuiButton
        testId="font-size-button"
        isMenuOpen={Boolean(anchorEl)}
        onClickAction={handleOpenMenu}
        isActive={isActive(editor, selected)}
        width={58}
        outlinedColorWhenActive={'#00283A'}
      >
        {selected}
        <Icon>
          <IcoArrowDropDownRounded />
        </Icon>
      </PyMuiButton>

      <PyMuiMenu
        testId="font-size-menu"
        id="select-font-family-menu"
        menuListProps="select-font-size-button"
        anchorEl={anchorEl}
        isMenuOpen={Boolean(anchorEl)}
        handleClose={handleClose}
      >
        {defaultFontSizes.map(({ name, value }) => (
          <PyMuiMenuItem
            key={name}
            testId={`font-size-menu-item-${value}`}
            onClick={() => handleSelectFontSize(name, value)}
            isActive={selected === name}
            menuItem={name}
            customStyle={{ minWidth: 58 }}
          />
        ))}
      </PyMuiMenu>
    </FontSizeContainer>
  );
};

export default FontSize;
