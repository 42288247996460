import { ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import IcoDrag from 'components/icon/icons/IcoDrag';
import IcoMeatball from 'components/icon/icons/IcoMeatball';
import { ConsoleLogger } from 'utils/ConsoleLogger';
import { useScrollToSection } from './useScrollToSection';

type SectionProps = {
  title: string;
  id: string;
  isActive: boolean;
};

export default function Section({ title, id, isActive }: SectionProps) {
  const scrollToSection = useScrollToSection(id);

  return (
    <ListItem
      key={id}
      disablePadding
      data-testid="section-title-textfield-input"
      sx={{
        '&:hover': {
          [`& .section-${id}-drag-icon`]: {
            opacity: '1 !important',
            width: '100% !important',
          },
          [`& .section-${id}-meatball-icon`]: {
            opacity: '1 !important',
          },
        },
      }}
    >
      <ListItemButton
        onClick={scrollToSection}
        sx={{
          padding: '0.5rem 0.625rem 0.5rem 0.5rem',
          borderRadius: '0rem 0.5rem 0.5rem 0rem',
          background: isActive ? '#353636' : 'transparent',
          boxShadow: isActive
            ? '0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
            : 'none',
          '&:hover': {
            background: isActive ? '#353636' : 'transparent',
          },
        }}
      >
        <IcoDrag
          className={`section-${id}-drag-icon`}
          style={{
            display: 'block',
            marginRight: '0.5rem',
            cursor: 'grab',
            opacity: 0,
            maxWidth: '1em',
            width: '0%',
            transition: 'opacity 0.2s ease-in-out, width 0.8s ease-in-out',
          }}
          onClick={(e) => {
            e.stopPropagation();
            ConsoleLogger.log('TODO: dragging');
          }}
          color={isActive ? '#fff' : '#353636'}
        />
        <ListItemText
          data-section-id={id}
          sx={{ color: isActive ? '#fff' : '#000' }}
          primary={
            <Typography
              fontFamily="Gellix"
              variant="body2"
              sx={{
                WebkitFontSmoothing: isActive ? 'subpixel-antialiased' : 'initial',
                fontFeatureSettings: "'liga' off, 'clig' off",
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {title}
            </Typography>
          }
        />
        <IcoMeatball
          data-testid="section-menu"
          className={`section-${id}-meatball-icon`}
          style={{
            marginLeft: '0.2rem',
            flexShrink: 0,
            width: '1.5rem',
            height: '1.5rem',
            display: 'block',
            opacity: 0,
            transition: 'opacity 0.2s ease-in-out',
          }}
          onClick={(e) => {
            e.stopPropagation();
            ConsoleLogger.log('TODO: menu');
          }}
          color={isActive ? '#fff' : '#353636'}
        />
      </ListItemButton>
    </ListItem>
  );
}
