import { SecondaryMenuWrapper } from '../component';
import { TextBlockSettings } from './designSettings/textblock';
import { AdvancedSpacing } from './designSettings/AdvancedSpacing';
import { ImageBlockSettings } from './designSettings/ImageBlock/ImageBlockSettings';
import { GridBlockType } from 'components/editor/shared/gridBlockType';
import { RowSettings, ColumnSettings, TableDefaultSettings, FooterRowSettings } from './TableSettings';
import { RootState } from '../../grid/reduxStore/Store';
import { useSelector } from 'react-redux';
import { TableSettingsTypes } from './TableSettings/types';
import { DefaultStyleSettings } from './DefaultStyleSettings/DefaultStyleSettings.tsx';
import { EditorDefaultSidePanelType } from '../../grid/reduxStore/blockStyleSettingsSlice.ts';

const panelMapping = {
  [GridBlockType.TEXT]: TextBlockSettings,
  [GridBlockType.IMAGE]: ImageBlockSettings,
  [TableSettingsTypes.TABLE_ROWS]: RowSettings,
  [TableSettingsTypes.TABLE_COLUMNS]: ColumnSettings,
  [TableSettingsTypes.TABLE_FOOTER_ROWS]: FooterRowSettings,
  [TableSettingsTypes.TABLE_DEFAULTS]: TableDefaultSettings,
  [EditorDefaultSidePanelType.DEFAULT_STYLE_SETTINGS_PANEL]: DefaultStyleSettings,
} as const;

export default function SidePanelModels() {
  const { activeSidePanel, isAdvancedSpacingModelOpen } = useSelector((state: RootState) => state.blockStyleSettings);

  const ModelToRender = isAdvancedSpacingModelOpen ? AdvancedSpacing : panelMapping[activeSidePanel as keyof typeof panelMapping];

  return ModelToRender ? (
    <SecondaryMenuWrapper isSecondaryMenuActive>
      <ModelToRender />
    </SecondaryMenuWrapper>
  ) : null;
}
