import { CSSObject } from '@emotion/react';

export const HEADING_FONT_SIZES: Record<number, number> = {
  1: 42,
  2: 32,
  3: 24,
  4: 18,
};

export const defaultStyle: CSSObject = {
  '.tiptap': {
    '&.ProseMirror': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      '&.ProseMirror-focused': {
        outlineWidth: 0,
      },
    },
    '&.ProseMirror p': {
      marginBottom: 0,
      paddingTop: 12,
      paddingBottom: 12,
    },
    mark: {
      padding: '0.1rem 0.3rem',
    },
  },
};
