import { css, CSSObject, Global } from '@emotion/react';
import { useAppSelector } from '../grid/reduxStore/Store.ts';
import { selectAllTextDefaultStyles } from '../grid/reduxStore/textDefaultStylingSlice.ts';

const TextDefaultStylesLoader = () => {
  const textDefaultStyles = useAppSelector(selectAllTextDefaultStyles);

  const blocksWithTiptapStyle: CSSObject = {
    '.editor__page__draggable .tiptap': textDefaultStyles,
  };

  return <Global styles={css(blocksWithTiptapStyle)} />;
};

export default TextDefaultStylesLoader;
