import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setActiveSidePanel } from '../../../grid/reduxStore/blockStyleSettingsSlice.ts';
import { SecondaryMenu } from '../../component';
import { TableSettingsTypes } from '../TableSettings/types.ts';

export function DefaultStyleSettings() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleBackButtonClick = () => {
    dispatch(setActiveSidePanel({ type: TableSettingsTypes.TABLE_ROWS }));
  };

  return (
    <SecondaryMenu
      testId={'default-styles-settings'}
      menuTitle={t('document.grid.default_styles.title')}
      backButtonOnClick={handleBackButtonClick}
    >
      <span>{t('document.grid.default_styles.title')}</span>
    </SecondaryMenu>
  );
}
