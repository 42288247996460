import { Box, List } from '@mui/material';
import Section from './Section';
import useActiveSection from './useActiveSection';
import { useOrderedSections } from './useOrderedSections';

export default function SectionsList() {
  const orderedSections = useOrderedSections();
  const { activeSection } = useActiveSection();

  return (
    <Box data-testid="sections-list-side-panel" width="100%" height="100%" paddingTop="1.25rem" paddingRight="0.6rem">
      <List>
        {orderedSections.map((section, index) => (
          <Section key={section.id} title={section.title} id={section.id} isActive={index === activeSection} />
        ))}
      </List>
    </Box>
  );
}
