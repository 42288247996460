import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './Store.ts';
import { CSSObject } from '@emotion/react';
import { HEADING_FONT_SIZES } from '../../GridDndEditor/TiptapEditor/defaultStyles.ts';

type TextDefaultStylingType = CSSObject;
export type TextDefaultStylingState = Partial<Record<TextDefaultTypes, TextDefaultStylingType>>;

enum TextDefaultTypes {
  HEADING_1 = 'h1',
  HEADING_2 = 'h2',
  HEADING_3 = 'h3',
  HEADING_4 = 'h4',
  NORMAL = 'p',
}

const sharedStyles = {
  fontFamily: 'Plus Jakarta Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  color: '#212121',
};

const tiptapTextDefaultStyle: TextDefaultStylingState = {
  [TextDefaultTypes.HEADING_1]: {
    ...sharedStyles,
    fontSize: HEADING_FONT_SIZES[1],
    fontWeight: 'bold',
  },
  [TextDefaultTypes.HEADING_2]: {
    ...sharedStyles,
    fontSize: HEADING_FONT_SIZES[2],
    fontWeight: 'bold',
  },
  [TextDefaultTypes.HEADING_3]: {
    ...sharedStyles,
    fontSize: HEADING_FONT_SIZES[3],
  },
  [TextDefaultTypes.HEADING_4]: {
    ...sharedStyles,
    fontSize: HEADING_FONT_SIZES[4],
  },
  [TextDefaultTypes.NORMAL]: {
    ...sharedStyles,
    fontSize: 14,
  },
};

const textDefaultStylingSlice = createSlice({
  name: 'editor-text-default-styling',
  initialState: tiptapTextDefaultStyle,
  reducers: {},
});

export const textDefaultStylingReducer = textDefaultStylingSlice.reducer;
export const selectAllTextDefaultStyles = (state: RootState) => state.textDefaultStylingReducer;
