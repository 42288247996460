import { useSelector } from 'react-redux';
import { type RootState } from '../grid/reduxStore/Store';

type SimpleSection = {
  id: string;
  order: number;
  title: string;
};

export const useOrderedSections = (): SimpleSection[] => {
  const allSections = useSelector((state: RootState) => state.gridBlockReducer.sections);
  const orderedSections = Object.values(allSections).sort((a, b) => a.order - b.order);
  return orderedSections;
};
