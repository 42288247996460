import { useContext } from 'react';
import { IconButton } from '@mui/material';

import { setActiveSidePanel, setIsAdvancedSpacingModelOpen } from '../../grid/reduxStore/blockStyleSettingsSlice';
import { SelectionContext } from '../SelectedBlockInfoProvider';
import { GridBlockType } from 'components/editor/shared/gridBlockType';
import IcoTune from '../../../icon/icons/IcoTune';
import { useSectionId } from '../../Sections/SectionIdProvider';
import { useBlockStyleSettings } from '../../hooks/useBlockSettings';
import { useAppDispatch } from '../../grid/reduxStore/Store';

interface BlockSettingsProps {
  type: GridBlockType;
  blockId: string | null;
}

function BlockSettings({ type, blockId }: BlockSettingsProps) {
  const classValue = type?.toUpperCase();
  const iconButtonSX = {
    width: '28px',
    height: '28px',
    color: 'white',
  };
  const { setSelectedBlockInfoByIcon } = useContext(SelectionContext);
  const dispatch = useAppDispatch();
  const { addBlock } = useBlockStyleSettings();
  const sectionId = useSectionId();

  return (
    <IconButton
      size={'small'}
      sx={iconButtonSX}
      onClick={() => {
        setSelectedBlockInfoByIcon({ blockId: blockId, sectionId: sectionId });
        dispatch(setActiveSidePanel({ type: GridBlockType[type] }));
        dispatch(setIsAdvancedSpacingModelOpen(false));
        blockId && addBlock(blockId);
      }}
      data-testid={`${classValue}-settings-icon`}
    >
      <IcoTune fontSize="inherit" />
    </IconButton>
  );
}

export default BlockSettings;
