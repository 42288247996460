import { Editor, EditorEvents } from '@tiptap/react';
import { Level } from '@tiptap/extension-heading';
import { Fragment, MouseEvent, useEffect, useMemo, useState } from 'react';
import Icon from '../components/Icon';
import { ILabels } from '../types';
import { PyMuiButton, PyMuiMenu, PyMuiMenuItem } from '../components/generalComponents';
import IcoArrowDropDownRounded from '../../../../icon/icons/IcoArrowDropDownRounded';
import { HEADING_FONT_SIZES } from '../defaultStyles';
import { Box } from '@mui/material';
import useFeatureFlag from '../../../../../hooks/useFeatureFlag.ts';
import { FeatureFlags } from '../../../../../utils/featureFlags.ts';
import { EditorDefaultSidePanelType, setActiveSidePanel } from '../../../grid/reduxStore/blockStyleSettingsSlice.ts';
import { useAppDispatch } from '../../../grid/reduxStore/Store.ts';
import { useTranslation } from 'react-i18next';

export const headingLevels: Level[] = [1, 2, 3, 4];

export const isHeadingActive = (editor: Editor) => headingLevels.some((level) => editor.isActive('heading', { level }));

type Props = {
  editor: Editor;
  headingLabels?: ILabels['headings'];
};

type SelectedLevel = Level | 0 | undefined;

const Heading = ({ editor, headingLabels }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState<SelectedLevel>(0);
  const isTextDefaultAvailable = useFeatureFlag([FeatureFlags.spTextDesignDefault])[FeatureFlags.spTextDesignDefault];

  const selectedLabel = useMemo(() => {
    if (selected === undefined) return '';
    return (
      headingLabels?.[`h${selected}`] || (selected ? `Heading ${selected}` : headingLabels?.normalText || t('editor.toolbar.normal_text'))
    );
  }, [selected, headingLabels]);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  const handleSelectHeading = (level: Level) => {
    editor.chain().focus().toggleHeading({ level }).run();
    setSelected(level);
    handleCloseMenu();
  };

  const handleDefaultStyleClick = () => {
    dispatch(setActiveSidePanel({ type: EditorDefaultSidePanelType.DEFAULT_STYLE_SETTINGS_PANEL }));
  };

  const handleSelectNormalText = () => {
    editor.chain().focus().setParagraph().run();
    setSelected(0);
    handleCloseMenu();
  };

  useEffect(() => {
    const updateSelectedLevel = ({ editor }: EditorEvents['transaction']) => {
      const { from, to } = editor.state.selection;
      const headingsInSelection: (Level | 0)[] = [];

      if (editor.state.selection.empty) {
        const parent = editor.state.selection.$from.parent;
        if (parent.type.name === 'heading') {
          headingsInSelection.push(parent.attrs.level);
        } else if (parent.type.name === 'paragraph') {
          headingsInSelection.push(0);
        }
      } else {
        // For non-empty selection, check all nodes between selection
        editor.state.doc.nodesBetween(from, to, (node) => {
          if (node.type.name === 'heading') {
            headingsInSelection.push(node.attrs.level);
          } else if (node.type.name === 'paragraph') {
            headingsInSelection.push(0);
          }
        });
      }

      const headingsSet = new Set(headingsInSelection);
      if (headingsSet.size === 1) {
        setSelected(Array.from(headingsSet)[0]);
      } else {
        setSelected(undefined);
      }
    };

    editor.on('transaction', updateSelectedLevel);
    return () => {
      editor.off('transaction', updateSelectedLevel);
    };
  }, [editor]);

  return (
    <Box component="span" display="flex" alignItems="center" alignSelf="stretch" flexDirection="row" justifyContent="flex-start">
      <PyMuiButton
        testId="heading-button"
        isMenuOpen={Boolean(anchorEl)}
        onClickAction={handleOpenMenu}
        isActive={isHeadingActive(editor)}
        width={144}
      >
        <span style={{ fontSize: 12, minWidth: 112, textAlign: 'left' }}>{selectedLabel}</span>
        <Icon>
          <IcoArrowDropDownRounded />
        </Icon>
      </PyMuiButton>
      <PyMuiMenu
        testId="heading-menu"
        id="select-heading-menu"
        menuListProps="select-heading-button"
        anchorEl={anchorEl}
        isMenuOpen={Boolean(anchorEl)}
        handleClose={handleCloseMenu}
      >
        <PyMuiMenuItem
          testId="normal-text"
          menuItem={headingLabels?.normalText || t('editor.toolbar.normal_text')}
          onClick={handleSelectNormalText}
        />
        {headingLevels.map((level, index, arr) => (
          <PyMuiMenuItem
            testId={`heading-menu-item-${level}`}
            key={level}
            onClick={() => handleSelectHeading(level)}
            isActive={editor.isActive('heading', { level })}
            fontSize={HEADING_FONT_SIZES[level]}
            isFontWeightBold={level !== arr[arr.length - 1]}
            menuItem={headingLabels?.[`h${level}`] || `Heading ${level}`}
          />
        ))}
        {isTextDefaultAvailable && (
          <Fragment>
            <li
              style={{
                backgroundColor: 'rgba(0, 150, 136, 0.12)',
                borderTop: '1px dashed #009688',
                borderBottom: '1px dashed #009688',
                height: '8px',
                display: 'block',
              }}
            ></li>
            <PyMuiMenuItem
              testId="edit-default-style"
              menuItem={t('editor.toolbar.editor_default_style')}
              onClick={handleDefaultStyleClick}
            />
          </Fragment>
        )}
      </PyMuiMenu>
    </Box>
  );
};

export default Heading;
